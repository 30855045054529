<template>
  <div>
    <b-row>
      <b-col>
        <v-autocomplete
          hide-details
          v-if="_.size(stores) > 0"
          :items="stores"
          :label="$t('STORE')"
          item-text="name"
          item-value="id"
          v-model="selectedStoreId"
          v-on:change="doSelectStore"
        ></v-autocomplete>
      </b-col>
      <b-col>
        <v-autocomplete
          hide-details
          v-if="_.size(clients) > 0"
          :items="clients"
          :label="$t('STOCK_WHOLESALE_CLIENTS')"
          item-text="name"
          item-value="id"
          v-model="selectedClientId"
          v-on:change="doSelectClient"
        ></v-autocomplete>
      </b-col>
      <b-col>
        <v-autocomplete
          hide-details
          v-if="_.size(selectedClientStores) > 0"
          :items="selectedClientStores"
          :label="$t('STOCK_WHOLESALE_CLIENT_STORES')"
          item-text="name"
          item-value="id"
          v-model="selectedClientStoreId"
          v-on:change="doSelectClientStore"
        ></v-autocomplete>
      </b-col>
    </b-row>
    <b-button
      :disabled="selectedClientId == null || selectedClientStoreId == null"
      class="mr-3"
      variant="primary"
      @click="goNextStep()"
      >{{ $t("CONTINUE") }}</b-button
    >
  </div>
</template>
<script>
import {
  FETCH_ITEMS_BY_CLIENT_WHOLESALE_RETURNING,
  FETCH_CLIENTS_WITH_STORES
} from "@/modules/stock/store/stock.module";

import { FETCH_STORES_WAREHOUSES } from "@/core/services/store/shared_store.module";

import { mapGetters, mapState } from "vuex";

import _ from "lodash";
export default {
  name: "StepStockActionSelectClientReturning",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    doGetItems: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    wizardStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      stores: [],
      clients: [],
      selectedStoreId: null,
      selectedClientId: null,
      selectedClientStoreId: null
    };
  },
  components: {},
  mounted() {
    let vm = this;
    vm.getClientsWithStores();
    vm.getStoresWarehouses();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    tempDoGetItems: {
      get: function() {
        return this.doGetItems;
      },
      set: function(newValue) {
        this.$emit("update:doGetItems", newValue);
      }
    },
    selectedStore() {
      let vm = this;
      return _.find(vm.stores, { id: vm.selectedStoreId });
    },
    selectedClient() {
      let vm = this;
      return _.find(vm.clients, { id: vm.selectedClientId });
    },
    selectedClientStores() {
      let vm = this;
      return _.size(vm.selectedClient) > 0 ? vm.selectedClient.stores : [];
    },
    selectedClientStore() {
      let vm = this;
      return _.find(vm.selectedClient.stores, {
        id: vm.selectedClientStoreId
      });
    },
    storesParams() {
      let storesParams = {};
      return storesParams;
    },
    itemsParams() {
      let vm = this;
      let itemsParams = {
        storeId: vm.selectedStoreId,
        clientId: vm.selectedClientId,
        clientStoreId: vm.selectedClientStoreId
      };
      return itemsParams;
    }
  },
  watch: {
    storesParams(value) {
      let vm = this;
    }
  },
  methods: {
    getStoresWarehouses() {
      let vm = this;
      let params = this.storesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_WAREHOUSES, apiParams)
        .then(data => {
          this.$set(vm, "stores", data.data);
          if (_.size(data.data) == 1) {
            //Ако е само един магазин, да вземе първия, че да не се налага да избират 1 от 1
            this.$set(vm, "selectedStoreId", data.data[0].id);
            vm.doSelectStore();
          } else {
            //Ако е някой админ и управлява повече от 1 магазин, да може да избира между всички негови
            this.$set(vm, "selectedStoreId", null);
          }
        })
        .catch(response => {
          console.log(response);
        });
    },
    getClientsWithStores() {
      let vm = this;
      let params = this.storesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_CLIENTS_WITH_STORES, apiParams)
        .then(data => {
          this.$set(vm, "clients", data.data);
          this.$set(vm, "selectedClientId", null);
          this.$set(vm, "selectedClientStoreId", null);
          vm.tempFirstLoader = false;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getItems() {
      let vm = this;
      let params = this.itemsParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_ITEMS_BY_CLIENT_WHOLESALE_RETURNING, apiParams)
        .then(data => {
          vm.tempItems = data.data;
          vm.tempDoGetItems = false; //prevent reload same items if selectStore is not changed
          this.updateWizardStep(2);
        })
        .catch(response => {
          console.log(response);
        });
    },
    doSelectStore() {
      let vm = this;
      this.$emit("update:selectedStore", vm.selectedStore);
    },
    doSelectClient() {
      let vm = this;
      vm.selectedClientStoreId = null;
      this.$emit("update:selectedClient", vm.selectedClient);
      this.$emit("updateWholesaleClientId", vm.selectedClient.id);
      this.$emit("updateWholesaleCurrencyId", vm.selectedClient.currencyId);
      this.$emit("updateWholesaleVatGroupId", vm.selectedClient.vatGroupId);
      this.$emit("updateWholesaleClientStoreId", null);
      this.$emit("update:selectedClientStore", {});
    },
    doSelectClientStore() {
      let vm = this;
      vm.tempDoGetItems = true;
      this.$emit("update:selectedClientStore", vm.selectedClientStore);
      this.$emit("updateWholesaleClientStoreId", vm.selectedClientStoreId);
    },
    goNextStep() {
      let vm = this;
      if (vm.tempDoGetItems == true) {
        this.getItems();
      } else {
        this.updateWizardStep(2);
      }
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
